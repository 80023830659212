<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" id="course_create" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              Tạo thông báo mới
            </h3>
          </div>
          <div class="card-toolbar">
            <router-link
                class="font-weight-bold font-size-3  btn btn-secondary"
                :to="{ name: 'information-index' }"
            ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Tiêu đề/Danh mục
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="name" name="Tiêu đề, danh mục" rules="required"
                                    v-slot="{ errors,classes }">
                  <input type="text" ref="name" name="name" class="form-control"
                         v-model="information.category"
                         placeholder="Tiêu đề, danh mục"
                         @input="changeTitle"
                         :class="classes"
                  >
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                      {{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Đường dẫn
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="name" name="Đường dẫn" rules="required"
                                    v-slot="{ errors,classes }">
                  <input type="text" ref="name" name="name" class="form-control"
                         v-model="information.link"
                         placeholder="Đường dẫn" :class="classes">
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                      {{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Số thứ tự
                  <span class="text-danger"></span></label>
                <input type="number" ref="name" name="name" class="form-control"
                       v-model="information.stt"
                       placeholder="Số thứ tự">

              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Áp dụng từ ngày
                  <span class="text-danger">*</span></label>
                <ValidationProvider vid="start_date" name="Ngày bắt đầu" rules="required"
                                    v-slot="{ errors,classes }">
                  <date-picker placeholder="Ngày bắt đầu" v-model="information.start_date" valueType="YYYY-MM-DD"
                               format="DD-MM-YYYY"
                               :class="classes"></date-picker>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                      {{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Đến ngày
                  <span class="text-danger"></span></label>
                <ValidationProvider vid="end_date" name="Ngày kết thúc" rules=""
                                    v-slot="{ errors,classes }">
                  <date-picker placeholder="Ngày kết thúc" v-model="information.end_date" valueType="YYYY-MM-DD"
                               format="DD-MM-YYYY"
                               :class="classes"></date-picker>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                      {{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-6">
              <div class="checkbox-inline">
                <label class="checkbox checkbox-success">
                  <input type="checkbox" name="Checkboxes5" v-model="information.is_all">
                  <span></span>Tất cả thành viên Edutalk có thể xem</label>
              </div>
            </div>
          </div>
          <div class="row" v-if="!information.is_all">
            <div class="col-md-4">
              <div class="form-group">
                <label>Phân quyền xem
                  <span class="text-danger">*</span></label>
                <ValidationProvider name="Loại tài khoản" rules="required" v-slot="{ errors,classes }">
                  <el-select v-model="cacheAccountType" @change="updateStateAccountType()" filterable multiple class="w-100" placeholder="Loại tài khoản" clearable>
                    <el-option
                        v-for="item in accountType"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                      <span style="float: left">{{ item.name }}</span>
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                      {{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Cấp bậc</label>
                <el-select v-model="levelSelect" filterable multiple class="w-100" placeholder="Cấp bậc" clearable>
                  <el-option
                      v-for="item in levels"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                    <span style="float: left">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Khu vực</label>
                <el-select v-model="areaSelect" filterable multiple class="w-100" placeholder="Khu vực" clearable>
                  <el-option
                      v-for="item in areas"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                    <span style="float: left">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="col-md-4" v-if="checkDepartment">
              <div class="form-group">
                <label>Phòng ban</label>
                <el-select v-model="departmentSelect" filterable class="w-100" placeholder="Phòng ban" clearable multiple>
                  <el-option
                      v-for="item in departments"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  >
                    <span style="float: left">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="row mb-4 align-items-center justify-content-center">
            <div class="col-md-4">
              <div class="checkbox-inline">
                <label class="checkbox checkbox-success">
                  <input type="checkbox" name="Checkboxes5" v-model="information.status">
                  <span></span>Hiển thị</label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="checkbox-inline">
                <label class="checkbox checkbox-success">
                  <input type="checkbox" name="is_special" ref="special" v-model="information.is_special">
                  <span></span>Nổi bật</label>
              </div>
            </div>
            <div class="col-md-4" v-if="information.is_special">
              <strong class="">Ảnh thumbnail <span class="text-danger">*</span></strong>
              <div class="checkbox-inline mt-2">
                <div class="" id="kt_profile_avatar">
                  <div class="button-wrapper">
                    <label for="upload">
                      <span class="btn btn-secondary bold" style="background-color: #00aff0; color: #FFFFFF"
                            @click="toggleShow">
                        <i class="fas fa-upload" style="color: #FFFFFF"></i> Upload
                      </span>
                    </label>
                    <ValidationProvider vid="thumbnail" name="Ảnh thumbnail"
                                        v-slot="{ errors,classes }">
                      <input type="file" ref="upload" style="display: none" :class="classes" @change="showPreview"
                             id="upload" class="upload-box">
                      <div class="fv-plugins-message-container">
                        <div data-field="thumbnail" data-validator="notEmpty" class="fv-help-block">
                          {{ errors[0] }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="text-danger" v-if="!current_photo">Chọn ảnh kích thước 884 x 182 để phù hợp với giao diện
                    website
                  </div>
                  <div v-if="current_photo" class="">
                       <span
                           class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow d-block float-right"
                           data-toggle="tooltip"
                           title="Cancel avatar"
                           @click="cancelAvatar"
                       >
                        <i class="ki ki-bold-close icon-xs text-muted"></i>
                      </span>
                    <img class="item-image" :src="current_photo"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="form-group mb-1 col-md-12">
              <label>
                  Kiểu thêm nội dung
                  <span class="text-danger">*</span>
              </label>
              <div class="col-md-12 p-0">
                <ValidationProvider vid="typeContent" name="Kiểu thêm nội dung" rules="required"
                                  v-slot="{ errors,classes }">
                  <el-select v-model="information.type_content" placeholder="Chọn kiểu thêm nội dung" @change="checkShow">
                    <el-option
                        v-for="(item, index) in listTypeContent"
                        :key="index"
                        :value="item.id"
                        :label="item.name">

                    </el-option>
                  </el-select>

                  <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                              errors[0]
                          }}
                      </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group mb-1 col-md-12" v-if="information.type_content === 0">
              <label>Nội dung
                <span class="text-danger">*</span></label>
              <ValidationProvider vid="content" name="Nội dung" rules="required"
                                  v-slot="{ errors,classes }">
                <ckeditor :editor="editor" v-model="information.content"></ckeditor>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                    {{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <div class="form-group mb-1 col-md-12" v-if="information.type_content === 1">
              <label>Liên kết
                <span class="text-danger">*</span>
              </label>
              <div class="col-md-12 p-0">
                <ValidationProvider vid="content" name="Liên kết" rules="required"
                                    v-slot="{ errors,classes }">
                  <textarea name="" id="" class="col-12 border" v-model="information.content" placeholder="Dán liên kết tại đây">
                  </textarea>

                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>

              </div>
            </div>
          </div>
        </div>
        <div class="separator separator-dashed my-5"></div>
        <div class="card-header">
          <div class="card-title align-items-start flex-column">
            <button
                type="submit"
                class="btn btn-success mr-2" :disabled="is_disable"
            >
              Tạo mới
            </button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>

</template>
<script>

import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {COUNT_SPECIAL_INFORMATION, CREATE_INFORMATION, GET_BUSINESS_LEVELS} from "../../../core/services/store/system/information.module";
import {GET_ACCOUNT_TYPE} from "@/core/services/store/user/account-type.module";
import {GET_LIST_AREAS} from "@/core/services/store/center/area.module";
import Multiselect from 'vue-multiselect'
// Date picker
import DatePicker from 'vue2-datepicker';
import myUpload from "vue-image-crop-upload/upload-2.vue";
import 'vue2-datepicker/index.css';
//
import {ChangeToSlug} from "@/core/filters";
//editor
import CustomEditor from "@/core/plugins/ckeditor5/custom-editor";
import {LIST_TYPE_CONTENT} from "@/core/services/store/system/mechanism.module";
import { STAFF, BOD, TEAMLEADER, HEAD_OF_DEPARTMENT } from '@/core/option/accountTypeOption';
import { ALL_DEPARTMENT } from '@/core/services/store/department/department.module';
import ThirdStepVue from '../test/wizad/ThirdStep.vue';

export default {
  components: {
    Multiselect,
    DatePicker,
    myUpload
  },
  name: "InformationCreate",
  data() {
    return {
      //Editor
      editor: CustomEditor,
      // Check show
      is_show_course_type: false,
      is_show_level: false,
      totalSpecial: 0,

      information: {
        content: '',
        type: '',
        status: true,
        publish: '',
        created_user: '',
        updated_user: '',
        category: '',
        stt: 1,
        is_special: false,
        id_parent: '',
        slug: '',
        start_date: '',
        end_date: '',
        is_all: false,
        type_system_information: '',
        link: '',
        items: '',
        roles: [],
        mechanism_type: 0,
        type_content: ''
      },
      imgDataUrl: '',
      default_photo: "media/users/blank.png",
      current_photo: null,
      image_name: null,
      image_type: null,
      check_spinner: false,
      show: false,
      is_disable: false,
      params: {
        token: '123456798',
        name: 'avatar'
      },
      headers: {
        smail: '*_~'
      },
      accountType: [],

      // Cache
      cacheAccountType: [],
      levelSelect: [],
      levels: [],
      money: {
        decimal: ',',
        thousands: ',',
        prefix: '',
        suffix: ' VNĐ',
        precision: 0,
        masked: false,
      },
      areaSelect: [],
      departmentSelect: [],
      areas: [],
      listTypeContent: LIST_TYPE_CONTENT,
      checkDepartment: false,
      departments: {}
    }
  },
  created() {
    this.$store.dispatch(COUNT_SPECIAL_INFORMATION).then((res) => {
      this.totalSpecial = res.data;
    });
  },
  mounted() {
    this.getAccountType()
    this.getListArea()
    this.getListDepartment()
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý thông báo", route: 'profile-1'},
      {title: "Tạo thông báo"}
    ]);
  },
  computed: {},
  watch: {
    information: {
      handler() {
        if (this.information.is_special && this.totalSpecial >= 2) {
          this.$message.warning('Không vượt quá 2 thông báo nổi bật');
          this.$refs.special.checked = false;
          this.information = {...this.information, is_special: false}
        }
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
    showPreview(e) {
      if (e.target.files.length > 0) {
        if (e.target.files.length > 0) {
          const file = e.target.files[0];
          if (typeof FileReader === "function") {
            const reader = new FileReader();
            reader.onload = event => {
              this.current_photo = event.target.result;
            };
            reader.readAsDataURL(file);
            this.image_name = file.name;
            this.image_type = file.type;
          } else {
            alert("Sorry, FileReader API not supported");
          }
        }
      }
    },
    cancelAvatar() {
      this.current_photo = '';
      this.$refs.upload.value = '';
    },
    changeTitle: function () {
      this.information.link = ChangeToSlug(this.information.category);
    },
    getListArea() {
      this.$store.dispatch(GET_LIST_AREAS, {}).then(res => {
        if (!res.error) {
          this.areas = res.data;
        }
      })
    },
    updateStateAccountType() {
      let decentali = [STAFF, BOD, TEAMLEADER, HEAD_OF_DEPARTMENT];
      const check = this.cacheAccountType.some(r=> decentali.includes(r))
      this.checkDepartment = false;

      if (check) {
        this.checkDepartment = true;
      }

      this.$store.dispatch(GET_BUSINESS_LEVELS, {
        account_type_ids: this.cacheAccountType.length > 0 ? this.cacheAccountType : [0],
      }).then((res) => {
        if (!res.error) {
          this.levels = res.data
          var updateLevelSelect = []
          _.forEach(this.levelSelect, (value, key) => {
            let check = this.levels.find(x => x.id == value);
            if (check) {
              updateLevelSelect.push(value)
            }
          });
          this.levelSelect = updateLevelSelect
        }
      });
    },
    getAccountType() {
      this.$store.dispatch(GET_ACCOUNT_TYPE, {
        limit: 1000,
      }).then((res) => {
        if (!res.error) {
          this.accountType = res.data.data
        }
      });
    },
    async onSubmit() {
      if (!this.current_photo && this.information.is_special) {
        this.$message.error('Ảnh thumbnail không được để trống');
        return;
      }
      this.is_disable = true;
      let avatar = this.current_photo;

      function urltoFile(url, filename, mimeType) {
        return (fetch(url)
                .then(function (res) {
                  return res.arrayBuffer();
                })
                .then(function (buf) {
                  return new File([buf], filename, {type: mimeType});
                }).catch((error) => {
                  return 1;
                })
        );
      }

      let formData = new FormData();
      this.information.is_special = this.information.is_special ? 1 : 0
      this.information.is_all = this.information.is_all ? 1 : 0
      this.information.status = this.information.status ? 1 : 0
      this.information.areas = this.areaSelect
      this.information.department_id = this.departmentSelect
      this.information.business_level = this.levelSelect
      this.information.account_type = this.cacheAccountType
      for (let key in this.information) {
        if (key !== 'roles') {
          formData.append(key, this.information[key]);
        } else {
          this.cacheAccountType.forEach(item => {
            formData.append('roles[]', item);
          })
        }
      }
      if (avatar && avatar.search('https') == -1) {
        let file = await urltoFile(avatar, this.image_name, this.image_type);
        if (file != 1) {
          formData.append('avatar', file);
        }
      }
      this.$store.dispatch(CREATE_INFORMATION, formData).then((res) => {
        if (res.error) {
          this.$notify({
            title: 'Thất bại',
            message: res.data.message,
            type: 'error'
          });
        } else {
          this.$router.push({name: "information-index"}).then(() => {
            this.$notify({
              title: 'Thành công',
              message: 'Tạo mới thành công',
              type: 'success'
            });
          })
        }
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
      }).finally(() => {
        this.is_disable = false;
      });
    },
    checkShow() {
      this.information.content = '';
    },
    getListDepartment() {
        this.$store.dispatch(ALL_DEPARTMENT, {all: true}).then((res) => {
            if (!res.error) {
                this.departments = res.data
            }
        }).catch((e) => {
            console.log(e, 'error')
        });
    },
  }
}
</script>
<style scoped>
.mx-datepicker {
  width: 100% !important;
}

.item-image {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  max-height: 300px;
  max-width: 300px;
  object-fit: contain;
}

img:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
